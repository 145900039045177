@import url('https://fonts.googleapis.com/css?family=Poppins');

.App {
  text-align: center;
  padding-top: 40px;
}

a{
  text-decoration: none !important;

}
body{
  background-color: #E2F0F9 !important;
  font-family: 'Poppins' !important;
  overflow-x: hidden;
}

.margin-100{
  margin: 100px 0;
}
.header{
  padding: 30px 0 30px 0;
}
.header img{
  width: 100%;

}
.header h1{
  font-family: 'Poppins';
font-style: normal;
font-weight: 900;
}
.header .color-blue{
  color: #00558B;
}

.f-w-700{
  font-weight: 700;
}
.navbar .logo{
  height: 40px;
}
.margin-t-20{
  margin-top: 20px;
}
.home .services .content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 50px 0;
  gap: 30px;
}
.home .main-content .services .content .s-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
}
.home .main-content .services .content .s-card img{
    max-height: 100px;
}
.home .main-content .services .content .s-card h3{
    margin-top: 30px;
}
@media only screen and (max-width: 600px) {
  .home .main-content .services .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}



.home .main-content .pillars{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  gap: 30px;
}
.home .main-content .pillars .p-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
}

.home .main-content .pillars .p-card h3{
  margin-top: 20px;
}
.home .main-content .pillars .p-card img{
  max-height: 100px;
}

.padding-30{
  padding: 30px 0;
}

@media only screen and (max-width: 600px) {
  .home .main-content .pillars{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

.home .main-content .contact{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 50px 0;
  width: 100%;
}
.home .main-content .contact .content{
    width: 100%;
}

.home .main-content .contact .left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home .main-content .contact .right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.home .main-content .contact .content.left .icon{
  max-width: 30px;
}
.home .main-content .contact .content.left .info{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.home .main-content .contact .content.left .info a{
  text-decoration: none;
  color: #000000;
}
@media only screen and (max-width: 600px) {
  .home .main-content .contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
  }
  .home .main-content .contact .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home .main-content .contact .right img{
    max-width: 150px;
  }
}

.footer{
  height: 200px;
}
